$dark-grey: #272727;
$beige: #b1a296;
$white-blue: #def2f1;
$light-grey: #aaabb8;
$main-font: 'Josefin Sans', sans-serif;

.regular-navbar {
    height: 4rem;
    width: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: 3;
    transition-timing-function: ease-in;
    transition: all 0.6s;
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: $main-font;
    font-weight: 600;
    overflow: hidden;
    a {
        color: white;
        text-decoration: none;
        &:hover {
            color: $beige;
            text-decoration: none;
        }
    };
    @media only screen and (max-width: 428px) {
        display: none;
    };
}

.nav-grey {
    background-color: $light-grey;
    color: $dark-grey;
    a {
        color: $dark-grey;
        &:hover {
            color: $white-blue;
            text-decoration: none;
        }
    };
    @media only screen and (max-width: 428px) {
        display: none;
    };
}

.contact-logo {
    height: 1.9rem;
    &:hover {
        transform: scale(1.1);
    }
    transition: 0.2s ease-in-out;
}

a:hover {
    color: #b1a296;
    text-decoration: none;
}

.nav-right, .nav-left {
    display: flex;
    align-items: center;
}

.nav-right {
    margin-right: 30px;
}

.nav-left {
    margin-left: 15px;
}

.nav-option {
    margin: 0 10px;
}

.mobile-nav {
    display: none;
    @media only screen and (max-width: 428px) {
        display: block;
        width: 100%;
        height: 2.4rem;
        font-family: 'Josefin Sans', sans-serif;
        position: fixed;
        bottom: 0;
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        background: #aaabb8;
    };
}

.mobile-nav-option {
    padding: 0.22rem 0.2rem;
    text-align: center;
    font-size: 0.6rem;
}

.mobile-icon {
    font-size: 1rem;
    margin: 0.2rem 0;
}

.mobile-nav a {
    text-decoration: none;
    color: white;
}

.navbar-container {
    @media only screen and (max-width: 428px) {
        bottom: 0;
    }
}