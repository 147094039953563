.strips {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    height: 80vh;
    // border: 1px solid white;
    position: relative;
    padding: 2%;
    justify-content: center;
    overflow: hidden;
}

.strip-item {
    height: 15%;
    width: 90%;
    max-width: 1500px;
    // background: pink;
    // border: 1px solid black;
    margin: 0.3% auto;
    position: relative;
    transition: 0.4s ease-in-out;
    &:hover {
        height: 90%;
    }
}

.strip-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px 0 rgba(39,39, 39, 0.5), 0 0 0 1px transparent;
    border-radius: 2px;
    background: #272727;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-pic {
    height: 100%;
    background-size: cover !important;
    background-position: top center !important;
    filter: grayscale(75%);
}

.project-info {
    padding: 2%;
    height: 40%;
    width: 96%;
    display: none;
    flex-direction: column;
    justify-content: center;
    h1 {
        font-size: 2rem;
        margin-bottom: 2%;
    }
    h3 {
        font-size: 1.25rem;
        margin-bottom: 1%;
        font-weight: normal;
    }
    p {
        font-size: 1.1rem;
        margin-bottom: 2%;
        line-height: 1.2rem;
    }
    .description {
       display: none; 
    }
}

.strip-item:hover .project-pic {
    height: 50%;
}
.strip-item:hover .project-info {
    display: flex;
}

@keyframes expand {
    0% {
        width: 20%;
    }
    100% {
        width: 80%;
    }
}