* {
  margin: 0;
}

body {
  background-color: #5d5c61;
}

/* .body {
  height: 50vh;
  background-color: #5d5c61;
  width: 100%;
} */