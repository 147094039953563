$font: 'Josefin Sans', sans-serif;

.MuiCardHeader-title {
    font-size: 1.43rem !important;
    font-family: $font !important;
    font-weight: bold !important;
    color: white !important;
    line-height: 0.5 !important;
    @media only screen and (max-width: 930px) {
        font-size: 1.2rem !important;
    }
    @media only screen and (max-width: 415px) {
        font-size: 1rem !important;
    }
    @media only screen and (max-width: 372px) {
        font-size: 0.7rem !important;
    }
}

.icon-img {
    height: 20px;
}

.cards {
    padding: 1rem;
    display: flex;
    // justify-content: space-evenly;
    margin: 3rem 0;
    flex-wrap: wrap;
}

.portfolio-card {
    background-color: #272727 !important;
    border-radius: 10px !important;
}

.map-card {
    width: 46%;
    margin: 2%;
    @media only screen and (max-width: 820px) {
        width: 96%;
    }
}

.info-icon {
    font-size: 2rem !important;
    color: white !important;
    &:hover {
        transform: scale(1.2)
    };
    transition: 0.25s ease-in-out !important;
}

.expand-info {
    color: white !important;
    font-family: $font !important;
}

.git-deployed-icons {
    color: white !important;
    display: table;
    margin: 0 auto !important;
    a {
        color: white;
    }
}

.git-icon {
    font-size: 2rem !important;
    display: table-cell;
    vertical-align: middle;
    margin-right: 1rem;
    &:hover {
        transform: scale(1.2);
    }
    transition: 0.25s ease-in-out !important;
}

.deploy-icon {
    font-size: 2.4rem !important;
    display: table-cell;
    vertical-align: middle;
    margin-left: 1rem;
    &:hover {
        transform: scale(1.2);
    }
    transition: 0.25s ease-in-out !important;;
}